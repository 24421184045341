@media print{
    .orders-view .navbar-default,
    .orders-view .head_info,
    .orders-view .toolbar-line,
    .orders-view .head_row,
    .orders-view .quick-access ul.links,
    .orders-view .footer-container{ display: none !important;}
    .orders-view a[href]:after {
        content:"" !important;
    }
    .orders-view .table > tbody > tr > td,
    .orders-view .orderdatarow{ font-size:20px !important;}
}
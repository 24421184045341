 body {
   font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  background: #4276a4;
 
  color: #555; overflow-x:hidden
}
a { color:#337ab7;}
h1,h2,h3,h4,h5,h6 {
  font-weight: 100;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10px;
}
h3,h4,h5 {
  margin-top: 5px;
  font-weight: 600;
}
.padrt15{ padding-right:15px;}
.padtb10{ padding-top:10px !important; padding-bottom:10px !important;}
img.gravatar{border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%;}
.masquerade{position:fixed; width:100%; top:0; left:0; background: red; z-index: 2000; color: #fff;}
  .masquerade a{ color: #fff; text-decoration: underline;}
.nav > li > a {
  color: #c9dff5;
  font-weight: 500;
  font-size:11px;
  padding: 14px 20px 14px 25px;
}
.nav.navbar-right > li > a {
  color: #999c9e;
}
.nav > li.active > a {
  color: #ffffff;
}
.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
  background-color: #497fae;
  color: white;
}
.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
  background-color: #ececec;
}
.nav > li > a i {
  margin-right: 6px;
}
.expanded-menu > li > a { border-top:1px #fff; }
.expanded-menu > li > a i { width:35px; height:44px; line-height:44px; float:left; margin:-12px 10px -12px -15px; position:relative; border-right:1px solid rgba(255, 255, 255, 0.05);}
.expanded-menu > li.active > a i{ color:#fff !important;}

.navbar {
  border: 0;
}
.navbar-default {
  background-color: transparent;
  border-color: #2f4050;
}
.navbar-top-links li {
  display: inline-block;
}
.navbar-top-links li:last-child {
  margin-right: 15px;
}
.navbar-top-links li a {
  padding: 20px 10px;
  min-height: 50px;
}
.dropdown-menu {
  border: medium none;
  border-radius: 3px;
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
  border-radius: 2px;
}
.dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}
.dropdown-menu > li > a.font-bold {
  font-weight: 600;
}
.navbar-top-links .dropdown-menu li {
  display: block;
}
.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}
.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
  font-size:12px;
}
.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}
.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
  width: 310px;
  min-width: 0;
}
.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}
.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}
.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}
.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}
.dropdown-messages,
.dropdown-alerts {
  padding: 10px 10px 10px 10px;
}
.dropdown-messages li a,
.dropdown-alerts li a {
  font-size: 12px;
}
.dropdown-messages li em,
.dropdown-alerts li em {
  font-size: 10px;
}
.nav.navbar-top-links .dropdown-alerts a {
  font-size: 12px;
}
.nav-header {
  padding: 15px 25px;
}
.sxim-init .nav-header {
  transition: all 0.5s;
}
.nav > li.active {
  background: #d64635;
}
.nav > li.active:hover {
  background: #d64635 !important;
}

.nav.nav-second-level > li.active {
  border: none;
}
.nav.nav-second-level.collapse[style] {
  height: auto !important;
}
.nav-header a {
  color: #DFE4ED; font-size: 12px;
}
.nav-header .text-muted {
  color: #8095a8;
}
.minimalize-btn {
  padding: 4px 12px;
  margin: 14px 5px 5px 20px;
  font-size: 14px;
  float: left;
}
.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: inline-table;
}
.navbar-form-custom .form-group {
  margin-bottom: 0;
}
.nav.navbar-top-links a {
  font-size: 12px;
}
.nav.navbar-top-links a  i{
  color : #428bca; 
}
.nav.navbar-top-links a  i.fa{
   font-size:14px; 
}

.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 14px;
  height: 60px;
  margin: 0;
  z-index: 2000;
}
.count-info .label {
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 6px;
  top: 12px;
}
.arrow {
  float: right;
}
.fa.arrow:before {
  content: "\f104";
}
.active > a > .fa.arrow:before {
  content: "\f107";
}
.nav-second-level li,
.nav-third-level li {
  border-bottom: none !important;
}
.nav-second-level li a {
  padding: 7px 10px 7px 10px;
  padding-left: 52px; font-weight:400;
}
.nav-third-level li a {
  padding-left: 62px; font-size:11px;
}
.nav-second-level li:last-child {
  margin-bottom: 10px;
}


.minimize-sidemenu .nav li:hover > .nav-second-level,
.minimize-sidemenu .nav li:focus > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 140px;
  height: auto;
}
body.minimize-sidemenu .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}
.minimize-sidemenu .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}
.minimize-sidemenu .nav-second-level {
  position: absolute;
  left: 70px;
  top: 0px;
  background-color: #2f4050;
  padding: 10px 10px 10px 10px;
  font-size: 12px; width:200px;
}
.minimize-sidemenu li.active .nav-second-level {
  left: 65px;
}
.photo-header {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  display: none;
  padding: 18px 0;
}
.logo-header {
	background:#2d5f8b; height:60px;
}
.logo-header a:hover { background: none !important;}
.navbar-fixed-top {
  background: #fff;
  transition-duration: 0.5s;
  border-bottom: 1px solid #e7eaec !important;
}
.navbar-fixed-top,
.navbar-static-top {
  background: #f3f3f4;
}
.fixed-nav #wrapper {
  margin-top: 60px;
}
.fixed-nav .minimalize-styl-2 {
  margin: 14px 5px 5px 15px;
}
.body-small .navbar-fixed-top {
  margin-left: 0px;
}
body.minimize-sidemenu .navbar-static-side {
  width: 70px;
  background: #4276a4;
}
body.minimize-sidemenu .profile-element,
body.minimize-sidemenu .nav-label,
body.minimize-sidemenu .nav-second-level,
body.minimize-sidemenu .navbar-default .nav li a span {
  display: none;
}
body.minimize-sidemenu .navbar-default .nav > li > a {
  font-size: 16px;
}
body.minimize-sidemenu .photo-header {
  display: block;
}
body.minimize-sidemenu .logo-header {
  display: none;
}
body.minimize-sidemenu .nav-header {
  padding: 0;
  background-color: #233646;
}
body.minimize-sidemenu #page-wrapper,
body.minimize-sidemenu .footer {
  margin: 0 0 0 70px;
}
body.minimize-sidemenu #page-wrapper {
  margin: 0 0 0 70px;
}
.btn {
  border-radius: 0px;
}
.btn.btn-sm .fa{
 font-size: 14px;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}
.btn-w-m {
  min-width: 120px;
}
.btn-primary.btn-outline {
  color: #1ab394;
}
.btn-success.btn-outline {
  color: #1c84c6;
}
.btn-info.btn-outline {
  color: #23c6c8;
}
.btn-warning.btn-outline {
  color: #f8ac59;
}
.btn-danger.btn-outline {
  color: #DF0000;
}
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}
.btn-primary {
  background-color: #428bca;
  border-color: #357ebd;
  color: #FFFFFF;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #3379b5;
  border-color: #2a6496;
  color: #FFFFFF;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color:#45b6af;
  border-color: #1dc5a3;
}
.btn-success {
  background-color: #45b6af;
  border-color: #3ea49d;
  color: #FFFFFF;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #3b9c96;
  border-color: #307f7a;
  color: #FFFFFF;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,

.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success.active[disabled],
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #1f90d8;
  border-color: #1f90d8;
}
.btn-info {
  background-color: #89c4f4;
  border-color: #72b8f2;
  color: #FFFFFF;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #68b4f1;
  border-color: #43a1ed;
  color: #FFFFFF;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info.active[disabled],
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #26d7d9;
  border-color: #26d7d9;
}
.btn-default {
  background-color: #e5e5e5;
  border-color: #e9e9e9;
  color: #333;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-color: lightgray;
  border-color: #e9e9e9;
  color: #333;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #cccccc;
  border-color: #cccccc;
}
.btn-warning {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: #FFFFFF;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #f7a54a;
  border-color: #f7a54a;
  color: #FFFFFF;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning.active[disabled],
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #f9b66d;
  border-color: #f9b66d;
}
.btn-danger {
  background-color: #f3565d;
  border-color: #f13e46;
  color: #FFFFFF;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #f1353d;
  border-color: #ec111b;
  color: #FFFFFF;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger.active[disabled],
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #ef6776;
  border-color: #ef6776;
}
.btn-link {
  color: inherit;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  color: #1c84c6;
}
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  background-image: none;
}
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #cacaca;
}
.btn-white {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  color: inherit;
  border: 1px solid #d2d2d2;
}
.btn-white:active,
.btn-white.active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}
.btn-white.disabled,
.btn-white.disabled:hover,
.btn-white.disabled:focus,
.btn-white.disabled:active,
.btn-white.disabled.active,
.btn-white[disabled],
.btn-white[disabled]:hover,
.btn-white[disabled]:focus,
.btn-white[disabled]:active,
.btn-white.active[disabled],
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white:hover,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white.active {
  color: #cacaca;
}
.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none; 
}
.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}
.btn-rounded {
  border-radius: 50px;
}
.btn-large-dim {
  width: 90px;
  height: 90px;
  font-size: 42px;
}
button.dim {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}
button.dim:active {
  top: 3px;
}
button.btn-primary.dim {
  box-shadow: inset 0px 0px 0px #16987e, 0px 5px 0px 0px #16987e, 0px 10px 5px #999999;
}
button.btn-primary.dim:active {
  box-shadow: inset 0px 0px 0px #16987e, 0px 2px 0px 0px #16987e, 0px 5px 3px #999999;
}
button.btn-default.dim {
  box-shadow: inset 0px 0px 0px #b3b3b3, 0px 5px 0px 0px #b3b3b3, 0px 10px 5px #999999;
}
button.btn-default.dim:active {
  box-shadow: inset 0px 0px 0px #b3b3b3, 0px 2px 0px 0px #b3b3b3, 0px 5px 3px #999999;
}
button.btn-warning.dim {
  box-shadow: inset 0px 0px 0px #f79d3c, 0px 5px 0px 0px #f79d3c, 0px 10px 5px #999999;
}
button.btn-warning.dim:active {
  box-shadow: inset 0px 0px 0px #f79d3c, 0px 2px 0px 0px #f79d3c, 0px 5px 3px #999999;
}
button.btn-info.dim {
  box-shadow: inset 0px 0px 0px #1eacae, 0px 5px 0px 0px #1eacae, 0px 10px 5px #999999;
}
button.btn-info.dim:active {
  box-shadow: inset 0px 0px 0px #1eacae, 0px 2px 0px 0px #1eacae, 0px 5px 3px #999999;
}
button.btn-success.dim {
  box-shadow: inset 0px 0px 0px #1872ab, 0px 5px 0px 0px #1872ab, 0px 10px 5px #999999;
}
button.btn-success.dim:active {
  box-shadow: inset 0px 0px 0px #1872ab, 0px 2px 0px 0px #1872ab, 0px 5px 3px #999999;
}
button.btn-danger.dim {
  box-shadow: inset 0px 0px 0px #ea394c, 0px 5px 0px 0px #ea394c, 0px 10px 5px #999999;

}
button.btn-danger.dim:active {
  box-shadow: inset 0px 0px 0px #ea394c, 0px 2px 0px 0px #ea394c, 0px 5px 3px #999999;
}
button.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: #fff;
  display: block;
  padding-top: 10px;
}
button.dim:active:before {
  top: 7px;
  font-size: 50px;
}
.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-size: 10px;
  font-weight: 500 !important; 
  padding: 3px 8px;
  text-shadow: none;
  border-radius:0;
  -moz-border-radius:0;
}
.form-horizontal .control-label, .form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline,
.control-label {
	
  font-weight: 500 !important; 

}
.badge {
  background-color: #fff;
  color: #ccc;
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  text-shadow: none;
}
.label-primary,
.badge-primary {
  background-color: #3379b5;
  color: #FFFFFF;
}
.label-success,
.badge-success {
  background-color: #45b6af;
  color: #ffffff;
}
.label-warning,
.badge-warning {
  background-color: #dfba49;
  color: #ffffff;
}
.label-warning-light,
.badge-warning-light {
  background-color: #f8ac59;
  color: #ffffff;
}
.label-danger,
.badge-danger {
  background-color: #f1353d;
  color: #FFFFFF;
}
.label-info,
.badge-info {
  background-color: #89c4f4;
  color: #FFFFFF;
}
.label-inverse,
.badge-inverse {
  background-color: #262626;
  color: #FFFFFF;
}
.label-white,
.badge-white {
  background-color: #FFFFFF;
  color: #5E5E5E;
}
.label-white,
.badge-disable {
  background-color: #2A2E36;
  color: #8B91A0;
}


/* PAGINATIN */
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #f4f4f4;
  border-color: #DDDDDD;
  color: inherit;
  cursor: default;
  z-index: 2;
}
.pagination > li > a,
.pagination > li > span {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  color: inherit;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 4px 10px;
  position: relative;
  text-decoration: none;
}


/* TOOLTIPS */
.tooltip-inner {
  background-color: #2F4050;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #2F4050;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #2F4050;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #2F4050;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #2F4050;
}



.stat-list li:first-child {
  margin-top: 0;
}
.stat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.stat-percent {
  float: right;
}
.stat-list li {
  margin-top: 15px;
  position: relative;
}


/* CIRCLE */
.img-circle {
  border-radius: 50%;
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}
.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #eee !important;
}
.show-grid {
  margin: 15px 0;
}


/* INPUTS */
label { font-size:12px;}
.btn { font-size:12px;}
.inline {
  display: inline-block !important;
}
.input-s-sm {
  width: 120px;
}
.input-s {
  width: 200px;
}
.input-s-lg {
  width: 250px;
}
.i-checks {
  padding-left: 0;
}
.form-control {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%; font-size:11px !important; height:30px;
  border-radius:0px !important; -moz-border-radius:0px !important;
}
.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn { font-size: 11px !important;   border-radius:0px !important; -moz-border-radius:0px !important;}
.form-control:focus {
  border-color: #357ebd;
}
.has-success .form-control {
  border-color: #357ebd;
}
.has-warning .form-control {
  border-color: #f8ac59;
}
.has-error .form-control {
  border-color: #ed5565;
}
.has-success .control-label {
  color: #1ab394;
}
.has-warning .control-label {
  color: #f8ac59;
}
.has-error .control-label {
  color: #ed5565;
}
.input-group-addon {
  background-color: #fff;
  border: 1px solid #E5E6E7;
  border-radius: 1px;
  color: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}


.sidebard-panel {
  width: 220px;
  background: #ebebed;
  padding: 10px 20px;
  position: absolute;
  right: 0;
}
.sidebard-panel .feed-element img.img-circle {
  width: 32px;
  height: 32px;
}
.sidebard-panel .feed-element,
.media-body,
.sidebard-panel p {
  font-size: 12px;
}
.sidebard-panel .feed-element {
  margin-top: 20px;
  padding-bottom: 0;
}
.sidebard-panel .list-group {
  margin-bottom: 10px;
}
.sidebard-panel .list-group .list-group-item {
  padding: 5px 0;
  font-size: 12px;
  border: 0;
}
.sidebar-content .wrapper {
  padding-right: 230px;
}

body.boxed-layout {
 
}
body.boxed-layout #wrapper {
  background-color: #2f4050;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
.block {
  display: block;
}
.clear {
  display: block;
  overflow: hidden;
}
a:hover,
a:focus {
  text-decoration: none;
}
.border-bottom {
  border-bottom: 1px solid #e7eaec !important;
}
.font-bold {
  font-weight: 600;
}
.font-noraml {
  font-weight: 400;
}
.text-uppercase {
  text-transform: uppercase;
}
.b-r {
  border-right: 1px solid #e7eaec;
}
.hr-line-dashed {
  border-top: 1px dashed #e7eaec;
  color: #ffffff;
  background-color: #ffffff;
  height: 1px;
  margin: 20px 0;
}
.hr-line-solid {
  border-bottom: 1px solid #e7eaec;
  background-color: rgba(0, 0, 0, 0);
  border-style: solid !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Sximo Modal */
.modal-content {
  background-clip: padding-box;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0 none;
  position: relative;
}
.modal-dialog {
  z-index: 1200;
}
.modal-body {
  padding: 20px 30px 30px 30px;
}

/* WRAPPERS */
#wrapper {
  width: 100%;
}
.wrapper {
  padding: 0 20px;
}
.wrapper-content {
  padding: 20px 10px 40px;
}
#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  position: relative !important;
  background:#eeeeee ;
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 240px;
    min-height: 1000px;
  }
}
.title-action {
  text-align: right;
  padding-top: 30px;
}

ul.unstyled,
ol.unstyled {
  list-style: none outside none;
  margin-left: 0;
}
.big-icon {
  font-size: 160px;
  color: #e5e6e7;
}

body.minimize-sidemenu .footer.fixed,
body.body-small.minimize-sidemenu .footer.fixed {
  margin: 0 0 0 70px;
}
body.body-small .footer.fixed {
  margin-left: 0px;
}
/* PANELS */
.page-heading {
  padding: 0px 10px 20px 10px;
}
.panel-heading h1,
.panel-heading h2 {
  margin-bottom: 5px;
}
/* TABLES */
.table-bordered {
  border: 1px solid #EBEBEB;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  background-color: #fff;
  border-bottom-width: 1px;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #e7e7e7;
}
.table > thead > tr > th {
  border-bottom: 1px solid #DDDDDD;
  vertical-align: bottom;  background:#fff;   font-size:12px; 
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #e7eaec;
  line-height: 1.42857;
  padding: 3px 8px;
  font-size:11px;
 	
  vertical-align: top; 
}
/* PANELS */
.panel.blank-panel {
  background: none;
  margin: 0;
}
.blank-panel .panel-heading {
  padding-bottom: 0;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #428bca;
  border-color: #357ebd #357ebd rgba(0, 0, 0, 0);
  border-bottom: #357ebd;
  border-image: none; font-weight:600;
  border-style: solid;
  border-width: 1px;
  color: #ffffff;
  cursor: default;
}
.nav.nav-tabs li {
  background: none;
  border: none;
}
.nav-tabs > li > a {
border-radius:0;
-moz-border-radius:0;	
  color: #555;
  font-weight: 500;
  background-color: #e6e6e6;
  padding: 5px 15px 5px 20px;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: #e6e6e6;
  color: #676a6c;
}
/* GLOBAL  */
.no-padding {
  padding: 0 !important;
}
.no-borders {
  border: none !important;
}
.no-margins {
  margin: 0 !important;
}
.no-top-border {
  border-top: 0 !important;
}
.ibox-content.text-box {
  padding-bottom: 0px;
  padding-top: 15px;
}
.border-left-right {
  border-left: 1px solid #e7eaec;
  border-right: 1px solid #e7eaec;
  border-top: none;
  border-bottom: none;
}
.full-width {
  width: 100% !important;
}
.link-block {
  font-size: 12px;
  padding: 10px;
}
.nav.navbar-top-links .link-block a {
  font-size: 12px;
}
.link-block a {
  font-size: 10px;
  color: inherit;
}
body.minimize-sidemenu .branding {
  display: none;
}
img.circle-border {
  border: 6px solid #FFFFFF;
  border-radius: 50%;
}
.branding {
  float: left;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  padding: 17px 20px;
  text-align: center;
  background-color: #1ab394;
}
.login-panel {
  margin-top: 25%;
}
.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.icons-box .infont a i {
  font-size: 25px;
  display: block;
  color: #676a6c;
}
.icons-box .infont a {
  color: #a6a8a9;
}
.icons-box .infont a {
  padding: 10px;
  margin: 1px;
  display: block;
}
.ui-draggable .ibox-title {
  cursor: move;
}
.breadcrumb {
  background-color: #ffffff;
  padding: 0;
  margin-bottom: 0;
}
.breadcrumb > li a {
  color: inherit;
}
.breadcrumb > .active {
  color: inherit;
}
code {
  background-color: #F9F2F4;
  border-radius: 4px;
  color: #ca4440;
  font-size: 90%;
  padding: 2px 4px;
  white-space: nowrap;
}

/* BACKGROUNDS */
.gray-bg {
  background-color: #f3f3f4;
}
.white-bg {
  background-color: #ffffff;
}
.navy-bg {
  background-color: #1ab394;
  color: #ffffff;
}
.blue-bg {
  background-color: #1c84c6;
  color: #ffffff;
}
.lazur-bg {
  background-color: #23c6c8;
  color: #ffffff;
}
.yellow-bg {
  background-color: #f8ac59;
  color: #ffffff;
}
.red-bg {
  background-color: #ed5565;
  color: #ffffff;
}
.black-bg {
  background-color: #262626;
}
.panel-primary {
  border-color: #1ab394;
}
.panel-primary > .panel-heading {
  background-color: #1ab394;
  border-color: #1ab394;
}
.panel-success {
  border-color: #1c84c6;
}
.panel-success > .panel-heading {
  background-color: #1c84c6;
  border-color: #1c84c6;
  color: #ffffff;
}
.panel-info {
  border-color: #23c6c8;
}
.panel-info > .panel-heading {
  background-color: #23c6c8;
  border-color: #23c6c8;
  color: #ffffff;
}
.panel-warning {
  border-color: #f8ac59;
}
.panel-warning > .panel-heading {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: #ffffff;
}
.panel-danger {
  border-color: #ed5565;
}
.panel-danger > .panel-heading {
  background-color: #ed5565;
  border-color: #ed5565;
  color: #ffffff;
}
.progress-bar {
  background-color: #1ab394;
}
.progress-small,
.progress-small .progress-bar {
  height: 10px;
}
.progress-small,
.progress-mini {
  margin-top: 5px;
}
.progress-mini,
.progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0px;
}
.progress-bar-navy-light {
  background-color: #3dc7ab;
}
.progress-bar-success {
  background-color: #1c84c6;
}
.progress-bar-info {
  background-color: #23c6c8;
}
.progress-bar-warning {
  background-color: #f8ac59;
}
.progress-bar-danger {
  background-color: #ed5565;
}
.panel-title {
  font-size: inherit;
}
.jumbotron {
  border-radius: 6px;
}
.jumbotron h1 {
  margin-top: 0;
}
/* COLORS */
.text-navy {
  color: #1ab394;
}
.text-primary {
  color: inherit;
}
.text-success {
  color: #45b6af;
}
.text-info {
  color: #89c4f4;
}
.text-warning {
  color: #dfba49;
}
.text-danger {
  color: #f3565d;
}
.text-muted {
  color: #888888;
}


/* margin pading */
.p-xxs {
  padding: 5px;
}
.p-xs {
  padding: 10px;
}
.p-sm {
  padding: 15px;
}
.p-m {
  padding: 20px;
}
.p-md {
  padding: 25px;
}
.p-lg {
  padding: 30px;
}
.p-xl {
  padding: 40px;
}
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m {
  margin: 15px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-t-none {
  margin-top: 0;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-r-none {
  margin-right: 0;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-b-none {
  margin-bottom: 0;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.space-15 {
  margin: 15px 0;
}
.space-20 {
  margin: 20px 0;
}
.space-25 {
  margin: 25px 0;
}
.space-30 {
  margin: 30px 0;
}


/* LOGIN  LOCKSCREEN*/
.middle-box {

  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -200px;
  z-index: 100; padding:20px; 
  
}
.middle-box .logo-login {
  border-bottom:dashed 1px #ddd; padding:0px 0 10px;  text-align:center;
}

.middle-box h1 {
  font-size: 40px;letter-spacing:-2px; margin:5px 0; ;
}




@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 220px;
    min-height: 1000px;
  }
  .navbar-static-side {
    z-index: 101;
    position: absolute;
    width: 220px;
  }
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0px;
    min-height: 1000px;
  }
  .body-small .navbar-static-side {
    display: none;
    z-index: 1;
    position: absolute;
    width: 70px;
  }
  .body-small.minimize-sidemenu .navbar-static-side {
    display: block;
  }
  .lock-word {
    display: none;
  }
  .navbar-form-custom {
    display: none;
  }
  .navbar-header {
    display: inline;
    float: left;
  }
  .sidebard-panel {
    z-index: 2;
    position: relative;
    width: auto;
    min-height: 100% !important;
  }
  .sidebar-content .wrapper {
    padding-right: 0px;
    z-index: 1;
  }
}
.bg-white { background:#fff;}

/*Nestable lists*/
.dd { position: relative; display: block; margin: 0; padding: 0; max-width: 600px; list-style: none; font-size: 13px; line-height: 20px; }
.dd-list { display: block; position: relative; margin: 0; padding: 0; list-style: none; }
.dd-list .dd-list { padding-left: 30px; }
.dd-collapsed .dd-list { display: none; }
.dd-item,
.dd-empty,
.dd-placeholder { display: block; position: relative; margin: 0; padding: 0; min-height: 20px; font-size: 13px; line-height: 20px; }
.dd-handle { cursor:default;display: block; margin: 5px 0; padding: 7px 10px; color: #333; text-decoration: none; border: 1px solid #ddd;
background: #fff;
}

.dd-handle:hover { color: #FFF; background: #4D90FD; border-color:#428BCA; }

.dd-item > button { color:#555;font-family: FontAwesome;display: block; position: relative; cursor: pointer; float: left; width: 25px; height: 20px; margin: 8px 2px; padding: 0; text-indent: 100%; white-space: nowrap; overflow: hidden; border: 0; background: transparent; font-size: 10px; line-height: 1; text-align: center; }
.dd-item > button:before {  display: block; position: absolute; width: 100%; text-align: center; text-indent: 0; }
.dd-item > button[data-action="collapse"]:before { }
.dd-placeholder,
.dd-empty { margin: 5px 0; padding: 0; min-height: 30px; background: #FFF; border: 1px dashed #b6bcbf; box-sizing: border-box; -moz-box-sizing: border-box; }
.dd-empty { border: 1px dashed #bbb; min-height: 100px; background-color: #e5e5e5;
background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
-webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
-moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
background-size: 60px 60px;
background-position: 0 0, 30px 30px;
}
.dd-dragel { position: absolute; pointer-events: none; z-index: 9999; }
.dd-dragel > .dd-item .dd-handle { margin-top: 0; }
.dd-dragel .dd-handle {
-webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
}

.dd3-content { 
display: block; 
margin: 5px 0; 
padding: 7px 10px 7px 40px; color: #333; text-decoration: none;
background: none repeat scroll 0 0 #FFFFFF;
border: 1px solid #DDDDDD;
color: #333333;
}
.dd3-content:hover { background: #fff; }
.dd-dragel > .dd3-item > .dd3-content { margin: 0; }
.dd3-item > button { margin-left: 35px; }
.dd3-handle { position: absolute; 
margin: 0; left: 0; top: 0; 
cursor: all-scroll; 
width: 34px; 
text-indent: 100%; 
white-space: nowrap; 
overflow: hidden;
border: 1px solid #3276B1;
background: #428BCA;
height:36px;
box-shadow:1px 1px 0 rgba(255, 255, 255, 0.2) inset;
}
.dd3-handle:before { content: '='; display: block; position: absolute; left: 0; top: 5px; width: 100%; text-align: center; text-indent: 0; color: #fff; font-size: 20px; font-weight: normal; }
.dd3-handle:hover { background: #4E9DFF; } 


.infobox {
    border-left: 3px solid #EEEEEE;
    margin: 0 0 35px;
    padding: 20px;
}
.infobox h5 {
    margin-bottom: 6px;
    margin-top: 0;
}
.infobox p:last-child {
    margin-bottom: 0; font-size: 11px !important;
}
.infobox code {
    background-color: #FFFFFF;
    border-radius: 3px;
}
.infobox-default {
    background-color: #F5F5F5;
    border-color: #DDDDDD;
}
.infobox-default h5 {
    color: #555555;
}
.infobox-danger {
    background-color: #FDF7F7;
    border-color: #D65C4F;
}
.infobox-danger h5 {
    color: #D65C4F;
}
.infobox-warning {
    background-color: #FCF8F2;
    border-color: #E7804F;
}
.infobox-warning h5 {
    color: #E7804F;
}
.infobox-info {
    background-color: #F2F7F9;
    border-color: #1992C2;
}
.infobox-info h5 {
    color: #1992C2;
}
.infobox-success {
    background-color: #F4FAF5;
    border-color: #239169;
}
.infobox-success h5 {
    color: #239169;
}

.info-blocks {
    list-style: none outside none;
    margin: 0 0 30px;
    padding: 0;
    text-align: center;
    width: 100%;
}
.info-blocks > li {
    display: inline-block;
    margin: 0 10px 5px;
    min-width: 150px;
    text-align: center;
    white-space: nowrap;
}
.info-blocks > li > a > i {
    display: inline-block;
    font-size: 48px;
    margin-bottom: 20px;
}
.info-blocks > li > a:hover {
    color: #FFFFFF;
    opacity: 0.8;
}
.top-info {
    margin: 16px 14px 14px;
}
.top-info > a {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
}
.top-info > a:hover {
    color: #FFFFFF;
    opacity: 0.8;
}
.top-info > small {
    display: block;
    font-size: 11px;
    margin-top: -2px;
}
.bottom-info {
    border-radius: 0 0 2px 2px;
    display: block;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 8px 5px;
}
@media (max-width: 478px) {
	.info-blocks > li {
		margin: 10px 0;
		width: 100%;
	}
}

.sbox-content h1,
.sbox-content h2,
.sbox-content h3,
.sbox-content h4,
.sbox-content h5,
.sbox-title h1,
.sbox-title h2,
.sbox-title h3,
.sbox-title h4,
.sbox-title h5 {
  margin-top: 5px;
}
.sbox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0; border:solid 1px #ddd;  border-top: none;
}
.sbox:after,
.sbox:before {
  display: table;
}
.sbox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #357ebd;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  height: 48px;
 
}
.sbox-title h3{  float: left; display:inline-block; }
.sbox-tools {
    display: inline-block;
    float: right;
    margin-top: 0;
    padding: 0;
    position: relative;
}
.sbox-tools a {
    color: #c4c4c4;
    cursor: pointer;
    margin-left: 5px;
}
.sbox-content {
  background-color: #f9f9f9;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #ddd;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px;
}
.sbox-title h5 {
    display: inline-block;
    float: left;
    font-size: 14px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
}
.sbox-tools {
    display: inline-block;
    float: right;
    margin-top: 0;
    padding: 0;
    position: relative;
}

.page-header { padding:20px 15px 20px; margin:0; background:#fff; border-top:solid 1px #ddd }
fieldset { margin:10px 0;}
fieldset legend { font-size:16px; padding:10px 0; font-weight:500;}
.table-responsive { background:#fff;  border:solid 1px #ddd; overflow-x:auto;}
.toolbar { margin:10px 0; clear:both; text-align:right;}
.toolbar-line { margin:15px 0; clear:both; }
.page-content-wrapper { padding:0 15px;}
.ribon-sximo { margin:20px 0;}

.panel { background:none !important; border-color:transparent !important;}
.panel.panel-default { background:none !important; border-color:#eee !important;}

/* Back to to */
.back-to-top {
  position: fixed;
  bottom: 0;
  right: 0px;
  text-decoration: none;
  color: #FFF;
  background-color: #4FA2C2;
  font-size: 12px;
  padding: 9px 12px;
  display: none;
}
.back-to-top i {
  font-size: 15px;
}
.back-to-top:hover {
  color: #FFF;
  background-color: #3A4B55;
}
.back-to-top:focus {
  color: #FFF;
}


/*Validation*/
.parsley-error-list {
  margin-top: 4px;
  padding: 0;
}
.parsley-error-list li {
  padding: 0;
  list-style: none;
  color: #cc0000;
}
.parsley-error {
  border-color: #c00 !important;
}
/* clear div */
.clr { clear:both;}

/* Tomorrow Theme */
/* Original theme - https://github.com/chriskempson/tomorrow-theme */
.prettyprint {
  background: white;
  font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco, Consolas, monospace;
  font-size: 12px;
  line-height: 1.5;
  border: 1px solid #ccc;
  padding: 10px;
}

.pln {
  color: #4d4d4c;
}

@media screen {
  .str {
    color: #718c00;
  }

  .kwd {
    color: #8959a8;
  }

  .com {
    color: #8e908c;
  }

  .typ {
    color: #4271ae;
  }

  .lit {
    color: #f5871f;
  }

  .pun {
    color: #4d4d4c;
  }

  .opn {
    color: #4d4d4c;
  }

  .clo {
    color: #4d4d4c;
  }

  .tag {
    color: #c82829;
  }

  .atn {
    color: #f5871f;
  }

  .atv {
    color: #3e999f;
  }

  .dec {
    color: #f5871f;
  }

  .var {
    color: #c82829;
  }

  .fun {
    color: #4271ae;
  }
}
@media print, projection {
  .str {
    color: #006600;
  }

  .kwd {
    color: #006;
    font-weight: bold;
  }

  .com {
    color: #600;
    font-style: italic;
  }

  .typ {
    color: #404;
    font-weight: bold;
  }

  .lit {
    color: #004444;
  }

  .pun, .opn, .clo {
    color: #444400;
  }

  .tag {
    color: #006;
    font-weight: bold;
  }

  .atn {
    color: #440044;
  }

  .atv {
    color: #006600;
  }
}
/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
}

/* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L4,
li.L5,
li.L6,
li.L7,
li.L8,
li.L9 {
  /* */
}

/* Alternate shading for lines */
li.L1,
li.L3,
li.L5,
li.L7,
li.L9 {
  /* */
}

.icon-classes-showcase > span {
    background: none repeat scroll 0 0 #FCFCFC;
    border: 1px solid #DDDDDD;
    display: inline-block;
    font-size: 12px;
    margin: 0.5em 1em 0.5em 0;
    padding: 8px;
    width: 15em;
}
.icon-classes-showcase > span > i {
    float: left;
    margin: 1px 6px 0 0;
}
.icon-show  { font-size: 13px !important; padding: 0 10px;}
.icon-show  i{ margin-right: 5px } 
.icon-show  .col-md-3{ padding: 5px; border: solid 1px #ddd; background:#fff; margin-bottom: 3px; } 

/* select */
.select-alt { float:none; padding:5px 2px; border:solid 1px #ddd; margin-right:5px; font-size: 11px; height: 31px;}

/* dashboard */
.shortcut { border:solid 1px #eee; border-top:solid 1px #428bca;}
.shortcut i{ font-size:50px;  } 
.shortcut span.h3{ font-weight:400 !important; font-size:16px; }
.shortcut span.h3 { color: #233646 }
.pagingtotal > p{ margin-top:10px;}
.footer-pagination ul.pagination { float:right;}
 .table-footer {
   margin-top: 10px;
 }

.theme-config {
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 80px;
}
.theme-config-box {
    margin-right: -220px;
    position: relative;
    transition-duration: 0.8s;
    z-index: 2000;
}
.theme-config-box.show {
    margin-right: 0;
}
.spin-icon {
    background: #428bca none repeat scroll 0 0;
    border-radius: 20px 0 0 20px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    left: 0;
    padding: 7px 10px 7px 13px;
    position: absolute;
    top: 0;
    width: 40px;
}
.skin-setttings {
    background: #f3f3f4 none repeat scroll 0 0;
    margin-left: 40px;
    width: 220px;
}
.skin-setttings .title {
    background: #efefef none repeat scroll 0 0;
    display: block;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
}
.setings-item {
    padding: 10px 30px;
}

.setings-item ul {
    padding: 0; margin: 0; list-style: none;
}
.setings-item ul li{ border-top: solid 1px #eee; }

.setings-item ul li a{
  color: #666; display: block; padding: 5px 2px; font-size: 11px; 

}
.setings-item ul li a span{
    width: 30px; height: 16px; border: solid 1px #ddd; 

}
.setings-item ul li a span.dark-blue-skin{ background: #364150;}
.setings-item ul li a span.light-blue-skin{ background: #4276a4;}
.setings-item ul li a span.black-skin{ background: #111;}
.setings-item ul li a span.default-skin{ background: #3ea49d;}



.nav-inside { background: #fff !important;}
.page-header { background: #fafafa;}
.page-header .breadcrumb { background: #fafafa;}
#page-wrapper { background: #fff;}

.footer.fixed {
    background: #f9f9f9;
    border-top: 1px solid #e7eaec;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    position: fixed;
    right: 0;
    z-index: 1000;
    font-size: 11px;
}
 table.wishlist a.productname{ display: block; font-size:1.2em;}
 table.wishlist textarea.wishcomments{ display: block; min-width:300px; min-height:100px; margin:10px 0;}
 table.wishlist button.savecomment{ margin-bottom:10px;}
 table.wishlist td.imgcell, table.wishlist td.actions{vertical-align: middle; text-align: center;}
 table.wishlist td.verticalmid{vertical-align: middle;}
 table.wishlist div.price{ font-size:2em; font-weight:bold; padding:10px 0;}
 table.wishlist td.addcart input{    padding: 6px 12px; display: block; margin:5px 0; width:100px; box-sizing: border-box}
 table.wishlist td.addcart .button{
   display: inline-block;
   padding: 6px 12px;
   box-sizing: border-box;
   margin-bottom: 0;
   font-size: 14px;
   font-weight: normal;
   line-height: 1.42857143;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background-color: #428bca;
   border-color: #357ebd;
   color: #FFFFFF;
   border:0;
   border-radius: 0px;
 }
 /*My Orders*/
.orders > .order{ margin:15px 0; border:1px solid #ddd; border-radius: 5px;float: left; width: 100%;}
  .orders > .order > .orderhead{background:#f6f6f6; padding:12px 20px; border-radius: 5px 5px 0 0;}
    .orderplaced,.ordertotal {display: inline-block; vertical-align: top;}
  .orders > .order > .orderbody{ background: #fff; padding:20px; border-radius: 0 0 5px 5px;float: left; width: 100%;}
    .orders > .order > .orderbody .orderleft{ width:75%; float: left;}
      .orderitemname{margin-bottom:5px;}
      .orderitemprice{ margin-bottom:5px; color: #d0481f;}
      .orders > .order > .orderbody .orderleft .orderimg{float: left; width: 100px; height: 100px;}
      .afterimg button[type="submit"]{padding: 5px 10px;}
    .orders > .order > .orderbody .orderright{ width:25%; float: left; vertical-align: top;}
  .orders > .order > .orderbody .orderright a.button{margin-bottom: 10px;}
    .orderright a.button{
      background: #eff0f3;
      background: -webkit-linear-gradient(top,#f7f8fa,#e7e9ec);
      background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
      display: block;
      position: relative;
      overflow: hidden;
      height: 29px;
      line-height: 29px;
      box-shadow: 0 1px 0 rgba(255,255,255,.6) inset;
      border-radius: 2px;
      color: #111;
      text-align: center;
      border:1px solid #6e6e6e;
    }
    .orderright a.button.yellow{
      background: #f3d078;
      background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
      background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    }
/*actions td*/
 .usersaddress td.actions,
 .core-users td.actions{ width:105px;}
 .reviews td.actions{ width:100px;}